// src/pages/Profile.tsx
import React, { useEffect, useState } from 'react';
import Attendance from '../components/Attendance';
import Fees from '../components/Fees';
import Courses from '../components/profileCourses';
import Tests from '../components/ProfileTest';

interface PaymentDetail {
  date: string;
  amount: number;
}

interface Course {
  name: string;
  status: 'completed' | 'in-progress';
}

interface Test {
  name: string;
  date: string; // Format: YYYY-MM-DD
  score: string; // Example: "85/100"
}

interface ProfileData {
  totalDays: number;
  attendedDays: number;
  totalInstallments: number;
  paidInstallments: number;
  payments: PaymentDetail[];
  totalAmount: number;
  remainingAmount: number;
  attendance: Record<string, 'present' | 'absent' | 'holiday'>;
  user: {
    profilePic: string;
    userName: string;
    className: string;
  };
  courses: Course[];
  tests: Test[];
}

const Profile: React.FC = () => {
  
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  
  useEffect(() => {
    // Simulate an API call to fetch profile data
    const fetchProfileData = async () => {
      // Simulated JSON response
      const data: ProfileData = {
        totalDays: 200,
        attendedDays: 180,
        totalInstallments: 6,
        paidInstallments: 4,
        payments: [
          { date: '2024-01-15', amount: 1500 },
          { date: '2024-02-15', amount: 1500 },
          { date: '2024-03-15', amount: 1500 },
          { date: '2024-04-15', amount: 1500 },
        ],
        totalAmount: 9000,
        remainingAmount: 3000,
        attendance: {
          '2024-08-01': 'present',
          '2024-08-02': 'present',
          '2024-08-03': 'holiday',
          '2024-08-04': 'absent',
          '2024-08-05': 'present',
          '2024-08-06': 'absent',
          '2024-08-07': 'holiday',
          '2024-08-08': 'present',
          '2024-08-09': 'present',
        },
        user: {
          profilePic: 'https://randomuser.me/api/portraits/men/32.jpg',
          userName: 'Rahul Kumar',
          className: 'Class 12 Science',
        },
        courses: [
          { name: 'Advanced Physics', status: 'completed' },
          { name: 'Organic Chemistry', status: 'completed' },
          { name: 'Calculus II', status: 'in-progress' },
          { name: 'Modern Biology', status: 'in-progress' },
          { name: 'Electronics Lab', status: 'completed' },
        ],
        tests: [
          { name: 'Physics Midterm', date: '2024-07-15', score: '85/100' },
          { name: 'Chemistry Quiz', date: '2024-07-22', score: '90/100' },
          { name: 'Mathematics Final', date: '2024-08-01', score: '95/100' },
          { name: 'Biology Test', date: '2024-06-10', score: '88/100' },
        ],
      };

      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setProfileData(data);
    };

    fetchProfileData();
  }, []);

  if (!profileData) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  const { totalDays, attendedDays, totalInstallments, paidInstallments, payments, totalAmount, remainingAmount, attendance, user, courses, tests } = profileData;

  return (
    <div className="flex flex-col items-center p-4 bg-gray-100 min-h-screen">
      {/* User Information Section */}
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mb-6 flex items-center">
        <img src={user.profilePic} alt="Profile" className="w-24 h-24 rounded-full border-2 border-gray-300" />
        <div className="ml-4">
          <h2 className="text-2xl font-bold">{user.userName}</h2>
          <p className="text-gray-700">{user.className}</p>
        </div>
      </div>

      
      
      {/* Courses Section */}
      <Courses courses={courses} />

      {/* Tests Section */}
      <Tests tests={tests} />

      {/* Attendance Section */}
      <Attendance
        totalDays={totalDays}
        attendedDays={attendedDays}
        attendance={attendance}
      />

      {/* Fees Section */}
      <Fees
        totalInstallments={totalInstallments}
        paidInstallments={paidInstallments}
        payments={payments}
        totalAmount={totalAmount}
        remainingAmount={remainingAmount}
      />
    </div>
  );
};

export default Profile;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_URL;  // Replace with actual base URL

interface Course {
    id: number;
    course_name: string;
}

interface Chapter {
    id: number;
    title: string;
    lectures: Lecture[];
}

interface Lecture {
    id: number;
    topic: string;
    description: string;
}

interface Option {
    id: number;
    text: string;
    isCorrect: boolean;
}

interface Question {
    id: number;
    text: string;
    options: Option[];
    image?: string; // Optional image URL
}

const CreateCompleteTest: React.FC = () => {
    const [courses, setCourses] = useState<Course[]>([]);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
    const [selectedChapter, setSelectedChapter] = useState<number | null>(null);
    const [selectedLecture, setSelectedLecture] = useState<number | null>(null);
    
    const [testName, setTestName] = useState<string>('');
    const [testId, setTestId] = useState<number | null>(null);
    const [lectureTestId, setLectureTestId] = useState<number | null>(null);

    const [questions, setQuestions] = useState<Question[]>([]);
    const [newQuestion, setNewQuestion] = useState<string>('');
    const [difficultyLevel, setDifficultyLevel] = useState<string>('easy');
    const [marks, setMarks] = useState<number>(1);
    const [imageFile, setImageFile] = useState<File | null>(null); // Add image file state
    const [options, setOptions] = useState<Option[]>([]);
    const [testSubmitted, setTestSubmitted] = useState<boolean>(false);

    const [testFor, setTestFor] = useState<string>('');
    const [createNewTest, setCreateNewTest] = useState<boolean>(false);

    // Get the token from localStorage
    const token = localStorage.getItem('token');

    // Create axios instance with token in headers
    const axiosInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
            Authorization: `Token ${token}`  // Add token to Authorization header
        }
    });

    // Fetch all courses on page load
    useEffect(() => {
        axiosInstance.get('/courses/courses')
            .then(response => setCourses(response.data))
            .catch(error => console.error('Error fetching courses:', error));
    }, []);

    

    // Fetch course details, including chapters and lectures
    const fetchCourseDetails = (courseId: number) => {
        if(courseId != 0){
            axiosInstance.get(`/courses/course-details/${courseId}/`)
                .then(response => {
                    setChapters(response.data.chapters);  // Set the chapters from the response
                })
                .catch(error => console.error('Error fetching course details:', error));
        }
    };
    

    useEffect(() => {
        if (testFor == 'Course' && selectedCourse !== 0) {
            setCreateNewTest(true);
        } else if (testFor == 'Chapter' && selectedChapter !== 0) {
            setCreateNewTest(true);
        } else if (testFor == 'Lecture' && selectedLecture !== 0) {
            setCreateNewTest(true);
        } else {
            setCreateNewTest(false);
        }

    }, [selectedCourse, selectedChapter, selectedLecture])

    // Create a new test
    const createTest = () => {
        axiosInstance.post('/online-test/tests/', {
            name: testName,
            description: 'Test for lecture',
            time_limit: 60 // Adjust the time limit if necessary
        })
        .then(response => {
            setTestId(response.data.id);
            // After test creation, create the LectureTest
        })
        .catch(error => console.error('Error creating test:', error));
    };

    // Create a LectureTest after creating the test
    const createLectureTest = (testId: number) => {
        if (createNewTest && testId !== null) {
            

            if (testFor == 'Course' && selectedCourse !== 0) {
                const payload = {
                    course_id: selectedCourse,
                    test_id: testId
                };

                axiosInstance.post('/online-test/create-course-test/', payload)
                .then(response => setLectureTestId(response.data.id))
                .catch(error => console.error('Error creating lecture test:', error));

            } else if (testFor == 'Chapter' && selectedChapter !== 0) {
                const payload = {
                    chapter_id: selectedChapter,
                    test_id: testId
                };

                axiosInstance.post('/online-test/create-chapter-test/', payload)
                .then(response => setLectureTestId(response.data.id))
                .catch(error => console.error('Error creating lecture test:', error));

            } else if (testFor == 'Lecture' && selectedLecture !== 0) {
                const payload = {
                    lecture_id: selectedLecture,
                    test_id: testId
                };

                axiosInstance.post('/online-test/create-lecture-test/', payload)
                .then(response => setLectureTestId(response.data.id))
                .catch(error => console.error('Error creating lecture test:', error));

            } else {
                //
            }
        }
    };

    // Add a new question
    const addQuestion = async () => {
        if (testId && newQuestion) {
            try {
                const questionData = { text: newQuestion, marks: marks.toString(), test: testId };
                
                let response;
                if (imageFile) {
                    // Create FormData object for file upload
                    const formData = new FormData();
                    formData.append('text', newQuestion);
                    formData.append('marks', marks.toString());
                    formData.append('test', testId.toString());
                    formData.append('image', imageFile);  // Append image file

                    response = await axiosInstance.post('/online-test/questions/', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                } else {
                    response = await axiosInstance.post('/online-test/questions/', questionData);
                }

                const questionId = response.data.id;

                options.forEach(option => {
                    addOption(questionId, option);
                });

                setQuestions([...questions, { id: questionId, text: newQuestion, options: [...options], image: response.data.image }]);
                setNewQuestion('');
                setOptions([]);
                setMarks(1);
                setImageFile(null); // Reset image file
            } catch (error) {
                console.error('Error adding question:', error);
            }
            // axiosInstance.post('/online-test/questions/', {
            //     text: newQuestion,
            //     marks: 1, // Adjust marks if needed
            //     test: testId
            // })
            // .then(response => {
            //     const questionId = response.data.id;
            //     // Add the options for the newly created question
            //     options.forEach(option => {
            //         addOption(questionId, option);
            //     });
            //     // Add the new question to the state and reset
            //     setQuestions([...questions, { id: questionId, text: newQuestion, options: [...options] }]);
            //     setNewQuestion('');
            //     setOptions([]);
            // })
            // .catch(error => console.error('Error adding question:', error));
        }
    };

    // Add an option for a question
    const addOption = (questionId: number, option: Option) => {
        axiosInstance.post('/online-test/options/', {
            option_text: option.text,
            is_correct: option.isCorrect,
            reason_text: "nothing",
            question: questionId
        })
        .catch(error => console.error('Error adding option:', error));
    };

    // Handle adding a new option locally before submitting
    const handleAddOption = () => {
        setOptions([...options, { id: options.length + 1, text: '', isCorrect: false }]);
    };


    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setImageFile(e.target.files[0]);
        }
    };

    // Final submission after all questions are added
    const submitTest = (testId: number) => {
        if (testSubmitted) {
            alert('Create a new Test again.')
            window.location.reload();
        } else {
            createLectureTest(testId);
            alert('Test creation completed successfully!');
            setTestSubmitted(true);
        }
    };

    return (
        <div className="container mx-auto p-6">
            {!testSubmitted && <>
            <h1 className="text-2xl font-bold mb-4">Create a Complete Test</h1>
            
            {/* Step 0: Select test is for */}
            <div className="mb-4">
                <label className="block mb-2">Test For (Course, Chapter, Lecture)</label>
                <select
                    value={ testFor || ''}
                    onChange={(e) => {
                        const test_for = String(e.target.value);
                        setTestFor(test_for);
                    }}
                    className="w-full border p-2"
                >
                    <option value="">Select Test For</option>
                    <option value="Course">Course</option>
                    <option value="Chapter">Chapter</option>
                    <option value="Lecture">Lecture</option>
                   
                </select>
            </div>
            

            {/* Step 1: Select Course */}
            { testFor && 
            <div className="mb-4">
                <label className="block mb-2">Select Course</label>
                <select
                    value={selectedCourse || ''}
                    onChange={(e) => {
                        const courseId = Number(e.target.value);
                        setSelectedCourse(courseId);
                        fetchCourseDetails(courseId);  // Fetch chapters and lectures
                    }}
                    className="w-full border p-2"
                >
                    <option value="">Select Course</option>
                    {courses ? (
                        <>
                            {courses.map(course => (
                                <option key={course.id} value={course.id} className='text-black'>{course.course_name}</option>
                            ))}
                        </>
                        ) : (
                        <></>
                        )
                    }
                    
                </select>
            </div>
            }

            {/* Step 2: Select Chapter */}
            {testFor != 'Course' && selectedCourse && chapters.length > 0 && (
                <div className="mb-4">
                    <label className="block mb-2">Select Chapter</label>
                    <select
                        value={selectedChapter || ''}
                        onChange={(e) => setSelectedChapter(Number(e.target.value))}
                        className="w-full border p-2"
                    >
                        <option value="">Select Chapter</option>
                        {chapters.map(chapter => (
                            <option key={chapter.id} value={chapter.id}>{chapter.title}</option>
                        ))}
                    </select>
                </div>
            )}

            {/* Step 3: Select Lecture */}
            {testFor != 'Chapter' && selectedChapter && (
                <div className="mb-4">
                    <label className="block mb-2">Select Lecture</label>
                    <select
                        value={selectedLecture || ''}
                        onChange={(e) => setSelectedLecture(Number(e.target.value))}
                        className="w-full border p-2"
                    >
                        <option value="">Select Lecture</option>
                        {chapters.find(chapter => chapter.id === selectedChapter)?.lectures.map(lecture => (
                            <option key={lecture.id} value={lecture.id}>{lecture.topic}</option>
                        ))}
                    </select>
                </div>
            )}



            {/* Step 4: Create Test */}
            {createNewTest && (
                <div className="mb-4">
                    <label className="block mb-2">Test Name</label>
                    <input
                        type="text"
                        value={testName}
                        onChange={(e) => setTestName(e.target.value)}
                        className="w-full border p-2"
                        placeholder="Enter test name"
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 mt-4"
                        onClick={createTest}
                        disabled={!testName || testId !== null}
                    >
                        {testId ? 'Test Created' : 'Create Test'}
                    </button>
                </div>
            )}

            </>}

            {/* Step 5: Add Questions and Options */}
            {testId && (
                <div className="mb-4">
                        {testSubmitted && testId && questions.length > 0 && (
                            <div className="mt-6">
                                <button
                                    className="bg-indigo-500 text-white px-6 py-2"
                                    onClick={() => window.location.reload()}
                                >
                                    <>Create New Test</>
                                </button>

                                <div className='my-3'>{testSubmitted && <Link to={`/edit-test/${testId}`} className="bg-indigo-500 text-white px-6 py-2 m-4">Edit it again</Link>}</div>
                            </div>
                        )}
                    
                    {!testSubmitted && <>
                    <label className="block mb-2">New Question</label>
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        className="w-full border p-2"
                        placeholder="Enter question"
                    />
                    <div className="mt-4 flex space-x-2">
                        <label className="block mt-4 mb-2">Upload Image</label>
                        <input type="file" onChange={handleImageChange} className="w-full border p-2" />
                    </div>
                    <div className="mt-4 flex space-x-4">
                        <label>Difficulty Level</label>
                        <select value={difficultyLevel} onChange={(e) => setDifficultyLevel(e.target.value)} className='border-2 '>
                            <option value="easy">Easy</option>
                            <option value="medium">Medium</option>
                            <option value="hard">Hard</option>
                        </select>
                    </div>
                    <div className="mt-4 flex space-x-2">
                        <label>Marks</label>
                        <input type="number" value={marks} onChange={(e) => setMarks(Number(e.target.value))}  className='border-2 '/>
                    </div>
                    <div className="mt-4">
                        <label className="block mb-2">Options</label>
                        {options.map((option, index) => (
                            <div key={index} className="flex items-center mb-2">
                                <input
                                    type="text"
                                    value={option.text}
                                    onChange={(e) =>
                                        setOptions(
                                            options.map((opt, i) =>
                                                i === index ? { ...opt, text: e.target.value } : opt
                                            )
                                        )
                                    }
                                    className="border p-2 mr-2"
                                    placeholder={`Option ${index + 1}`}
                                />
                                <input
                                    type="checkbox"
                                    checked={option.isCorrect}
                                    onChange={() =>
                                        setOptions(
                                            options.map((opt, i) =>
                                                i === index ? { ...opt, isCorrect: !opt.isCorrect } : opt
                                            )
                                        )
                                    }
                                    className="mr-2"
                                />
                                <label className="mr-2">Correct</label>
                            </div>
                        ))}
                        <button
                            className="bg-blue-500 text-white px-4 py-2"
                            onClick={handleAddOption}
                        >
                            Add Option
                        </button>
                    </div>
                    <button
                        className="bg-green-500 text-white px-4 py-2 mt-4"
                        onClick={addQuestion}
                    >
                        Add Question
                    </button>

                    </>}
                    
                    {/* Step 6: Show Created Questions */}
                    <div className="mt-6">
                        <h3 className="text-lg font-bold mb-2">Created Questions</h3>
                        {questions.map((question, index) => (
                            <div key={index} className="mb-4">
                                <h4 className="text-md font-bold">
                                    {index + 1}. {question.text}
                                </h4>
                                <img src={question.image}  className='w-40 h-full'/>
                                <ul className="list-disc pl-6">
                                    {question.options.map((option, i) => (
                                        <li key={i} className={option.isCorrect ? 'text-green-600' : ''}>
                                            {option.text} {option.isCorrect ? '(Correct)' : ''}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            )}


            {/* Final Submission Button */}
            {testId && questions.length > 0 && (
                <div className="mt-6">
                    <div className='my-3'>{testSubmitted && <Link to={`/edit-test/${testId}`} className="bg-indigo-500 text-white px-6 py-2 m-4">Edit it again</Link>}</div>
                    <button
                        className="bg-indigo-500 text-white px-6 py-2"
                        onClick={() => submitTest(testId)}
                    >
                        {testSubmitted ? <>Create New Test</> : <>Submit Test</> }
                    </button>
                    


                </div>
            )}
        </div>
    );
};

export default CreateCompleteTest;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface Question {
  id: number;
  text: string;
  marks: number;
  image?: string; // Optional image URL
  options: Option[];
}

interface Option {
  id: number;
  option_text: string;
  reason_text: string;
  is_correct: boolean;
}

interface Test {
  id: number ;
  name: string;
  description: string;
  time_limit: number;
  questions: Question[]; // Ensure questions is always an array
}

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with actual base URL

const EditTest: React.FC = () => {
  const { testId } = useParams<{ testId: string }>(); // Retrieve testId from URL
  const [test, setTest] = useState<Test | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  // const [questionId, setQuestionId] = useState<number | null>(null)
  const [questionList, setQuestionList] = useState<Question[]>([])
  const [originalQuestions, setOriginalQuestions] = useState<Question[]>([]);
  const [newQuestion, setNewQuestion] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);
  // const [questions, setQuestions] = useState<Question[]>([]);
  // Get the token from localStorage
  const token = localStorage.getItem('token');

  // Create axios instance with token in headers
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Token ${token}`, // Add token to Authorization header
    },
  });

  // Fetch test details when the component mounts
  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const response = await axiosInstance.get(`/online-test/tests/${testId}/`);
        const data: Test = response.data;

        // Ensure questions is always an array
        if (!Array.isArray(data.questions)) {
          data.questions = [];
        }

        setTest(data);        
  
      } catch (err) {
        console.error('Error fetching test details:', err);
        setError('Failed to load test details');
      } finally {
        setLoading(false);
      }
    };

    const fetchQuestions = async () => {
      try {
          const response = await axiosInstance.get(`/online-test/test/${testId}/questions/`);
          const data = response.data.map((q: Question) => ({
            ...q,
            id: q.id ?? 0,  // Ensure `id` is never null, assigning 0 or another default value
          }));
          setQuestionList(data);
          setOriginalQuestions(response.data); 
      } catch (error) {
          console.error("Error fetching questions:", error);
      }
    };

    const setTestData = () => {
      if (test && test.id && test.name && test.time_limit && test.description) {        
        setTest({
          id: test.id,
          name: test.name,
          time_limit: test.time_limit,
          description: test.description,
          questions: questionList,
        });
      }
    }

    
    fetchTestDetails();
    fetchQuestions();
    setTestData();

  }, [testId]);

  // Add a new question
const addQuestion = async () => {
  if (testId && newQuestion) {
    try {
      const response = await axiosInstance.post('/online-test/questions/', {
        text: newQuestion,
        marks: 1, // Adjust marks if needed
        test: testId
      });

      const questionId = response.data.id; // Get the new question's ID from the response

      // Find the index of the new question
      const qIndex = questionList.length; // New question will be added at the end

      // Create the new question object
      const newQuestionObject = {
        id: questionId,
        text: newQuestion,
        options: [...options], // Spread the existing options
        marks: 1,
      };

      // Update both questionList and originalQuestions with the new question
      setQuestionList([...questionList, newQuestionObject]);
      setOriginalQuestions([...originalQuestions, newQuestionObject]);

      // Add the options for the newly created question
      options.forEach(option => {
        addOption(questionId, option, qIndex); // Call the function to add options for the new question
      });


      // Reset the form fields after adding the new question
      setNewQuestion('');
      setOptions([]);
    } catch (error) {
      console.error('Error adding question:', error);
    }
  }
};


  // Add an option for a question
  const addOption = async(questionId: number, option: Option, qIndex: number) => {
    try {
      const response = await axiosInstance.post('/online-test/options/', {
        option_text: option.option_text,
        is_correct: option.is_correct,
        reason_text: "nothing",
        question: questionId,
      });
  
      const newOptionId = response.data.id; // Assuming `id` is returned in the response data
  
      // Update the question's option with the new `option_id`
      const updatedQuestions = [...questionList];
      updatedQuestions[qIndex].options.push({
        ...option,
        id: newOptionId, // Assign the new ID to the newly added option
      });
  
      setQuestionList(updatedQuestions); // Update the question list with the new option
      setOriginalQuestions(updatedQuestions);
  
      console.log('Option added successfully:', response.data); // Log the response data
    } catch (error) {
      console.error('Error adding option:', error);
    }
  };

  // Handle adding a new option locally before submitting
  const handleAddOption = () => {
      setOptions([...options, { id: 0, option_text: '', is_correct: false, reason_text: 'nothing' }]);
  };



  const handleSubmit = async () => {
    if (test) {
      try {
        await axiosInstance.put(`/online-test/tests/${test.id}/`, test);
        alert('Test updated successfully!');
      } catch (err) {
        console.error('Error updating test:', err);
        alert('Failed to update test.');
      }
    }
  };

  // Handle changes to the specific question text
const handleQuestionChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  qIndex: number,
  questionId: number
) => {
  const updatedQuestions = [...questionList];
  updatedQuestions[qIndex].text = e.target.value; // Modify the specific question's text
  setQuestionList(updatedQuestions);
};

// Handle changes to a specific option within a question
const handleOptionChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  qIndex: number,
  optionIndex: number
) => {
  // Create a deep copy of the questionList to avoid modifying originalQuestions
  const updatedQuestions = questionList.map((question) => ({
    ...question,
    options: question.options.map((option) => ({ ...option }))
  }));

  // Modify the specific option's text
  updatedQuestions[qIndex].options[optionIndex].option_text = e.target.value;

  // Update the state with the modified questions list
  setQuestionList(updatedQuestions);
};


// Check if the question text has changed
const hasQuestionChanged = (qIndex: number) => {
  if (originalQuestions[qIndex] && questionList[qIndex]) {
    return questionList[qIndex].text !== originalQuestions[qIndex].text;
  }
  return false;
};

// Check if an option within a question has changed
const hasOptionChanged = (qIndex: number, optionIndex: number) => {
  
  if (
    originalQuestions[qIndex] &&
    questionList[qIndex] &&
    originalQuestions[qIndex].options[optionIndex] &&
    questionList[qIndex].options[optionIndex]
  ) {

    
    return (
      questionList[qIndex].options[optionIndex].option_text !==
      originalQuestions[qIndex].options[optionIndex].option_text
    );
  }
  return false;
};

// Update the question text
const UpdateQuestion = async (questionId: number, questionText: string) => {
  try {
    await axiosInstance.put(`/online-test/questions/${questionId}/`, {
      id: questionId,
      text: questionText,
      test: testId,
      marks: 1,
    });

  } catch (err) {
    console.error('Error updating question:', err);
    alert('Failed to update question.');
  }
};

// Update a specific option within a question
const UpdateOption = async (
  optionId: number,
  questionId: number,
  qIndex:number,
  optionIndex:number,
  optionText: string
) => {
  try {
    await axiosInstance.put(`/online-test/options/${optionId}/`, {
      id: optionId,
      option_text: optionText,
      is_correct: false,
      reason_text: 'nothing',
      question: questionId,
    });

    
    // Create a deep copy of the questionList to avoid modifying originalQuestions
    const updatedQuestions = originalQuestions.map((question) => ({
      ...question,
      options: question.options.map((option) => ({ ...option }))
    }));

    // Modify the specific option's text
    updatedQuestions[qIndex].options[optionIndex].option_text = questionList[qIndex].options[optionIndex].option_text;

    // Update the state with the modified questions list
    setOriginalQuestions(updatedQuestions);
    

  } catch (err) {
    console.error('Error updating option:', err);
    alert('Failed to update option.');
  }
};

// Function to add a new option to a question
const addNewOption = (qIndex: number, questionId:number) => {
  const updatedQuestions = [...questionList];
  
  // Create a new option object (you can customize its structure as needed)
  const newOption = {
    id: 0, // Temporary unique ID (you'll replace it once the option is saved in the backend)
    option_text: 'Write Option Text',
    is_correct: false,
    reason_text: 'nothing'
  };


  // Add the new option to the options array for the specific question
  updatedQuestions[qIndex].options = [...updatedQuestions[qIndex].options, newOption];
  
  setQuestionList(updatedQuestions);
  setOriginalQuestions(updatedQuestions);
  addOption(questionId, newOption, qIndex)
};




  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold">Edit Test</h1>

      {/* Test Information */}
      {test && (
        <div className="mt-4">
          <label className="block text-lg">Test name</label>
          <input
            type="text"
            className="border px-4 py-2 w-full mt-2"
            value={test.name}
            onChange={(e) => setTest({ ...test, name: e.target.value })}
          />

          <label className="block text-lg mt-4">Test Description</label>
          <textarea
            className="border px-4 py-2 w-full mt-2"
            value={test.description}
            onChange={(e) => setTest({ ...test, description: e.target.value })}
          />


          <button
            className="bg-purple-500 text-white px-6 py-2 my-6"
            onClick={handleSubmit}
          >
            Save Changes
          </button>




          {/* Step 5: Add Questions and Options */}
          {testId && (
                <div className="mb-4">
                    
                    {/* {!testSubmitted && <> */}
                    <label className="block mb-2 font-bold text-2xl text-blue-500 ">Add New Question</label>
                    <input
                        type="text"
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        className="w-full border p-2"
                        placeholder="Enter question"
                    />
                    <div className="mt-4">
                        <label className="block mb-2">Options</label>
                        {options.map((option, index) => (
                            <div key={index} className="flex items-center mb-2">
                                <input
                                    type="text"
                                    value={option.option_text}
                                    onChange={(e) =>
                                        setOptions(
                                            options.map((opt, i) =>
                                                i === index ? { ...opt, option_text: e.target.value } : opt
                                            )
                                        )
                                    }
                                    className="border p-2 mr-2"
                                    placeholder={`Option ${index + 1}`}
                                />
                                <input
                                    type="checkbox"
                                    checked={option.is_correct}
                                    onChange={() =>
                                        setOptions(
                                            options.map((opt, i) =>
                                                i === index ? { ...opt, is_correct: !opt.is_correct } : opt
                                            )
                                        )
                                    }
                                    className="mr-2"
                                />
                                <label className="mr-2">Correct</label>
                            </div>
                        ))}
                        <button
                            className="bg-blue-500 text-white px-4 py-2"
                            onClick={handleAddOption}
                        >
                            Add Option
                        </button>
                    </div>
                    <button
                        className="bg-green-500 text-white px-4 py-2 mt-4"
                        onClick={addQuestion}
                    >
                        Add Question
                    </button>

                    {/* </>} */}
                    
                </div>
            )}


          {/* Questions Section */}
          {questionList && questionList.length > 0 ? (
            questionList.map((question, qIndex) => (
              <div key={question.id} className="mt-6">
                <label className="block text-lg">Question {qIndex + 1}</label>
                <input
                  type="text"
                  className="border px-4 py-2 w-full mt-2"
                  value={question.text}
                  onChange={(e) => handleQuestionChange(e, qIndex, question.id)}  // Handle the change
                  // onChange={(e) => handleQuestionChange(qIndex, 'text', e.target.value)}
                />
                <button className="bg-blue-500 text-white px-4 py-2 mt-2"
                  disabled={!hasQuestionChanged(qIndex)}
                  hidden= {!hasQuestionChanged(qIndex)}
                  onClick={() => UpdateQuestion(question.id, question.text)}
                  >Save changes</button>
                <p>{question.image}</p>
                <img src={`${question.image}`}  className='w-40 h-full'/>


                {/* Options Section */}
                <div className="mt-4">
                  <h2>Options:</h2>
                  {question.options?.map((option, oIndex) => (
                    <div key={option.id} className="flex items-center mb-2">
                      <input
                        type="text"
                        className="border px-4 py-2 w-full mt-2"
                        value={option.option_text}
                        onChange={(e) => handleOptionChange(e, qIndex, oIndex)}  // Handle option change
                      />
                      <input
                        type="checkbox"
                        className="ml-2"
                        checked={option.is_correct}
                        // onChange={(e) => handleOptionChange(qIndex, oIndex, 'is_correct', e.target.checked)}
                      />
                      <label className="ml-2">Correct Answer</label>
                      <button
                        className={`bg-blue-500 text-white px-4 py-2 mt-2 ${hasOptionChanged(qIndex, oIndex) ? '' : 'opacity-50 cursor-not-allowed'}`}
                        hidden={!hasOptionChanged(qIndex, oIndex)}  // Disable if the option text hasn't changed
                        onClick={() => UpdateOption(option.id, question.id, qIndex, oIndex, option.option_text)}
                      >
                        Save option
                      </button>
                      
                      
                    </div>
                  )) ?? <div>No options available</div>} {/* Fallback in case options is undefined */}
                </div>

                {/* Add Option Button */}
                <button
                  className="bg-blue-500 text-white px-4 py-2 mt-2"
                  onClick={() => addNewOption(qIndex, question.id)}
                >
                  Add New Option
                </button>
              </div>
            ))
          ) : (
            <div>No questions available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditTest;
